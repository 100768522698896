<script>
import { XIcon } from "@/components/ui-components/material-icons";
import BaseButton from "@/components/Reusable/BaseButton";
import CountDown from "@/components/Reusable/CountDown";
import AppHeader from "@/components/Header/AppHeader";
import TabTitle from "@/views/checkoutV3/elements/TabTitle";
import ChClientDetails from "@/views/checkoutV4/components/ClientDetails";
import ChApartmentsOverview from "@/views/checkoutV4/components/ApartmentsOverview";
import ChReview from "@/views/checkoutV4/components/Review";
import AppBreadcrumb from "@/components/AppBreadcrumb";
import { XModalCenter } from "@/components/ui-components/modal-center";
import { XFormInput } from "@/components/ui-components/form-input";
import { XLoadingWrapper } from "@/components/ui-components/loading";
import { XCircularBackground } from "@/components/ui-components/circular-background";
import { mapActions } from "vuex";
import api from "@/services/api";
import { NOTIFY } from "@/constants/names";
import { headerItems } from "@/views/checkoutV4/helper/headerComputed";
import Permission from "@/permission";
import HidePromoButton from "@/components/Reusable/HidePromoButton.vue";

import { formatDateToYMD } from "@/util/calendar";
import { addMonth } from "@/views/checkoutV3/helper/dateHelpers.js";

import { v3ServiceApi } from "@/services/v3/v3.service";
import { v4ServiceApi } from "@/services/v4/v4.service";

export default {
  name: "Index",
  components: {
    AppBreadcrumb,
    XLoadingWrapper,
    XIcon,
    BaseButton,
    CountDown,
    AppHeader,
    TabTitle,
    ChClientDetails,
    ChApartmentsOverview,
    ChReview,
    XModalCenter,
    XFormInput,
    XCircularBackground,
    HidePromoButton,
  },
  data() {
    return {
      wait: null,
      initWait: null,
      calcUpdateTimeout: null,
      calculatorChanges: {
        initial_payment: null,
        initial_payment_percentage: null,
        total_discount: null,
        m2_discount: null,
        months: null,
        monthly_payment_date: null,
        initial_payment_date: null,
        apartment_price: null,
        apartment_price_m2: null,
        payments: [],
        changedDates: [],
      },
      userComment: {
        vBind: "",
        showModal: false,
        applyButtonLoading: false,
      },
      showWarningModal: false,
      isFetching: false,
      isSubmitting: false,
      stepStateIdx: 0,
      expiry_at: undefined,
      permission: Permission,

      clientData: {},
      countryList: [],
      clientTypeList: [],

      order: {
        order_apartment: null,
        id: null,
        uuid: "",
        contract: "",
        status: {},
        type: {},
        apartment: {
          uuid: null,
          block: {
            building: {
              object: {},
            },
          },
        },
        order_parkings: [],
        payments: [],
        parent_id: null,
        booking_date: null,
        contract_path: null,
        initial_payment: null,
        transaction_price: null,
        contract_date: null,
        friends: false,
        per_square_price: null,
        sold_price: null,
        archived: false,
        edited_at: null,
        alias: null,
        version: 1,
        checkout_data: null,
        edit_type: null,
        extra_data: null,
        is_additional: false,
        discount_amount: null,
        client: null,
        currency_type: "usd",
        currency: null,
        payment_status: "unknown",
        discount: null,
        reorder_percent: 0,
        reorder_data: null,
        reorder_type: null,
        installment_month_price: null,
        installment_month: null,
        updated_by: null,
        deleted_by: null,
        created_at: "2025-01-28 17:36:22",
        updated_at: "2025-01-28 17:36:22",
        deleted_at: null,
        calcResult: {
          discounts: [
            {
              installment_months: [{}],
            },
          ],
        },
        calculation: {
          type: null,
          discount: null,
          discount_amount: null,
          currentInstallment: null,
        },
      },
      numberUpdated: false,
      dateUpdated: false,

      currentApartment: 0,
    };
  },

  async created() {
    await this.init();
  },

  computed: {
    fullInitial() {
      const discount =
        this.order.calculation.discount === "other"
          ? this.order.calcResult.discounts.find((el) => el.id == null)
          : this.order.calcResult.discounts.find(
              (el) => el.id === this.order.calculation.discount
            );
      if (this.order.calculation.type === "installment") {
        return discount.installment_months.find(
          (installment) =>
            installment.id === this.order.calculation.currentInstallment
        ).price_calculations.initial_payment;
      } else {
        return discount.price_calculations.initial_payment;
      }
    },
    totalForAll() {
      if (this.stepStateIdx <= 1) return 0;
      const discount =
        this.order.calculation.discount === "other"
          ? this.order.calcResult.discounts.find((el) => el.id == null)
          : this.order.calcResult.discounts.find(
              (el) => el.id === this.order.calculation.discount
            );
      if (this.order.calculation.type === "installment") {
        return discount.installment_months.find(
          (installment) =>
            installment.id === this.order.calculation.currentInstallment
        ).price_calculations.transaction_price;
      } else {
        return discount.price_calculations.transaction_price;
      }
    },
    isUpdateMode() {
      const pathUnits = this.$route.path.split("/");
      return pathUnits[pathUnits.length - 1] === "update";
    },
    isCreateMode() {
      const pathUnits = this.$route.path.split("/");
      return pathUnits[pathUnits.length - 1] !== "update";
    },
    headerItems,
    flexCenter() {
      return "d-flex justify-content-center align-items-center";
    },
  },

  methods: {
    handleContractDate(e) {
      console.log("date");
      this.order.contract_date = e.value;
      this.dateUpdated = true;
    },
    handleContractNumber(e) {
      console.log("number");
      this.order.contract = e.value;
      this.numberUpdated = true;
    },
    handleCalcUpdateWrap(e) {
      if (this.initWait) return;
      this.handleCalcUpdate(e);
    },
    async handleCalcUpdate(e) {
      const contr = {
        total_discount: "m2_discount",
        m2_discount: "total_discount",
        initial_payment: "initial_payment_percentage",
        initial_payment_percentage: "initial_payment",
        apartment_price: "apartment_price_m2",
        apartment_price_m2: "apartment_price",
      };
      if (this.wait) return;
      if (e.field === "type") {
        this.order.calculation.type = e.value;
        if (e.value === "installment") {
          this.order.calculation.currentInstallment =
            this.order.calcResult.discounts.find(
              (d) => d.id === this.order.calculation.discount
            ).installment_months[0].id;
          this.calculatorChanges.months = null;
        }
        return;
      }

      if (
        [
          "initial_payment",
          "initial_payment_percentage",
          "total_discount",
          "m2_discount",
          "months",
          "initial_payment_date",
          "monthly_payment_date",
          "apartment_price",
          "apartment_price_m2",
        ].includes(e.field)
      ) {
        this.calculatorChanges[e.field] = e.value;
        if (contr[e.field]) {
          this.calculatorChanges[contr[e.field]] = null;
        }
      }
      if (
        [
          "months",
          "discount",
          "installment",
          "initial_payment",
          "initial_payment_percentage",
          "total_discount",
          "m2_discount",
          "apartment_price",
          "apartment_price_m2",
        ].includes(e.field)
      ) {
        if (e.field === "discount") {
          this.order.calculation.discount = e.value;
          if (
            this.calculatorChanges.initial_payment ===
              this.calculatorChanges.initial_payment_percentage &&
            this.calculatorChanges.initial_payment === null
          )
            return;
          this.calculatorChanges.initial_payment =
            this.calculatorChanges.initial_payment_percentage = null;
        }
        if (e.field === "installment") {
          this.order.calculation.currentInstallment = e.value;
          if (
            this.calculatorChanges.initial_payment ===
              this.calculatorChanges.initial_payment_percentage &&
            this.calculatorChanges.initial_payment === null
          )
            return;
          this.calculatorChanges.initial_payment =
            this.calculatorChanges.initial_payment_percentage = null;
        }
        this.calculatorChanges.payments = [];
        this.calculatorChanges.changedDates = [];
      }

      this.updateCalcResult();
    },
    async handleUpdatePayment(e) {
      const current = this.calculatorChanges.payments.find(
        (el) => el.date_payment === e.date_payment && el.type === e.type
      );
      if (current) {
        current.amount = e.amount;
        if (e.type === "initial_payment") {
          this.calculatorChanges.changedDates =
            this.calculatorChanges.changedDates.filter(
              (el) => el !== e.date_payment
            );
          this.calculatorChanges.changedDates.push(e.date);
          current.date_payment = e.date;

          const unchangedSum = this.calculatorChanges.payments
            .filter(
              (el) =>
                el.type === "initial_payment" &&
                this.calculatorChanges.changedDates.includes(el.date_payment)
            )
            .reduce((acc, el) => acc + el.amount, 0);
          const changed = this.calculatorChanges.payments.filter(
            (el) =>
              el.type === "initial_payment" &&
              !this.calculatorChanges.changedDates.includes(el.date_payment)
          );

          let used = unchangedSum;
          const each =
            Math.ceil((this.fullInitial - used) / 1000 / changed.length) * 1000;
          changed.forEach((el) => {
            el.amount = Math.min(this.fullInitial - used, each);
            used = used + each;
          });
        } else {
          current.date_payment = e.date;
        }
      } else {
        e.date_payment = e.date;
        this.calculatorChanges.payments.push(e);
      }
      this.updateCalcResult();
    },
    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    addInitialPayment() {
      if (
        this.calculatorChanges.payments.filter(
          (el) => el.type === "initial_payment"
        ).length === 0
      ) {
        const first = Math.ceil(this.fullInitial / 2000) * 1000;
        const second = this.fullInitial - first;
        this.calculatorChanges.payments.push(
          {
            type: "initial_payment",
            amount: first,
            date_payment:
              this.order.calcResult.initial_payment_date.split(" ")[0],
          },
          {
            type: "initial_payment",
            amount: second,
            date_payment: formatDateToYMD(
              this.addDays(
                this.order.calcResult.initial_payment_date.split(" ")[0],
                1
              )
            ),
          }
        );
      } else {
        this.calculatorChanges.payments =
          this.calculatorChanges.payments.filter(
            (el) => el.type !== "initial_payment"
          );
        const first = Math.ceil(this.fullInitial / 3000) * 1000;
        const second = this.fullInitial - first * 2;
        this.calculatorChanges.payments.push(
          {
            type: "initial_payment",
            amount: first,
            date_payment:
              this.order.calcResult.initial_payment_date.split(" ")[0],
          },
          {
            type: "initial_payment",
            amount: first,
            date_payment: formatDateToYMD(
              this.addDays(
                this.order.calcResult.initial_payment_date.split(" ")[0],
                1
              )
            ),
          },
          {
            type: "initial_payment",
            amount: second,
            date_payment: formatDateToYMD(
              this.addDays(
                this.order.calcResult.initial_payment_date.split(" ")[0],
                2
              )
            ),
          }
        );
      }
      this.updateCalcResult();
    },
    deleteInitialPayment(payment) {
      this.calculatorChanges.payments = this.calculatorChanges.payments.filter(
        (el) => el.type !== "initial_payment"
      );
      this.calculatorChanges.changedDates = [];
      this.updateCalcResult();
    },
    async updateCalcResult() {
      return new Promise((resolve) => {
        if (this.calcUpdateTimeout) clearTimeout(this.calcUpdateTimeout);
        this.calcUpdateTimeout = setTimeout(async () => {
          const payload = {
            uuid: this.order.apartment.uuid,
          };
          if (this.isUpdateMode) {
            payload.order_uuid = this.order.uuid;
          }
          Object.entries(this.calculatorChanges).forEach(([key, value]) => {
            if (
              value ||
              (key === "initial_payment_percentage" && value != null)
            ) {
              payload[key] = value;
            }
          });
          this.startFetching();
          const calc = await v3ServiceApi.orders.calculatePrices(payload);
          this.finishFetching();
          if (
            calc.discount &&
            !calc.discounts.some((d) => d.id === calc.discount.id)
          ) {
            calc.discounts.unshift({
              ...calc.discount,
              deleted: true,
            });
          }
          this.order.calcResult = calc.data.result;
          if (this.order.calculation.discount !== "other") {
            let ds = calc.data.result.discounts;
            if (
              this.order.calculation.type === "custom" ||
              this.order.calculation.type === "installment"
            ) {
              ds = ds.filter((el) => el.type !== "promo");
            } else {
              ds = ds.filter((el) => el.type === "promo");
            }
            const lastD = ds.findLast(
              (d) => d.is_proper_initial_payment === true && d.type !== "other"
            );
            if (lastD) {
              this.order.calculation.discount = lastD.id;
            }
          }
          this.wait = true;
          setTimeout(() => {
            this.wait = false;
            resolve();
          }, 100);
        }, 50);
      });
    },
    handleCountryList(e) {
      this.countryList = e;
    },
    handleClientTypeList(e) {
      this.clientTypeList = e;
    },
    ...mapActions("notify", ["openNotify"]),
    async init() {
      try {
        this.startFetching();
        const orderId = this.$route.params.id;
        const {
          data: { data },
        } = await v4ServiceApi.orders.show({
          id: orderId,
        });
        const apartment = data.order_apartments[0].apartment;
        if (data) {
          const p = {
            uuid: apartment.uuid,
          };
          if (this.isUpdateMode) {
            p.order_uuid = data.uuid;
          }
          const calc = await v3ServiceApi.orders.calculatePrices(p);
          const calcResult = calc.data.result;
          const order_apartment = data.order_apartments[0].id;
          data.calcResult = calcResult;
          data.apartment = data.order_apartments[0].apartment;
          data.order_apartment = order_apartment;
          if (this.isUpdateMode) {
            this.clientData.clientId = data.client.id;
            const installment_month = data.installment_month_id;
            data.calculation = {
              type: installment_month
                ? "installment"
                : !data.discount || data.discount.type.key !== "promo"
                ? "custom"
                : "promo",
              discount: data.discount_id || "other",
              currentInstallment: installment_month,
            };

            this.calculatorChanges = data.frontend_data || {
              initial_payment: data.initial_payment || null,
              initial_payment_percentage: null,
              total_discount: data.discount_amount,
              months: data.order_apartments[0].months.sold,
              monthly_payment_date: data.payments
                .find((el) => el.type === "monthly")
                .date_payment.split(" ")[0],
              initial_payment_date: data.payments
                .find((el) => el.type === "initial_payment")
                .date_payment.split(" ")[0],
              apartment_price:
                data.discount_id == null
                  ? data.order_apartments[0].apartment_price
                  : null,
              payments: [],
            };
            if (!this.calculatorChanges.changedDates) {
              this.calculatorChanges.changedDates = [];
            }
            this.initWait = true;
            setTimeout(async () => {
              await this.updateCalcResult(data.currency?.id);
              setTimeout(() => (this.initWait = false), 2000);
            }, 100);
          } else {
            const firstDiscount = calcResult.discounts.filter(
              (d) => d.type !== "promo"
            )[0];
            this.expiry_at = data.order_hold.expiry_at;
            data.calculation = {
              type: "custom",
              discount: firstDiscount.id,
              currentInstallment: null,
            };
            data.contract_date = formatDateToYMD(new Date());
            this.startCounter();
          }
          this.order = data;
        }
      } catch (e) {
        console.log(e);
        this.toastedWithErrorCode(e);
        // this.redirect();
      } finally {
        this.finishFetching();
      }
    },

    redirect() {
      this.$router.push({
        name: "apartments",
        params: {
          object: this.$route.params.object,
        },
      });
    },

    startCounter() {
      this.expiry_at = this.$moment(this.expiry_at)
        .utcOffset("+0500")
        .format("YYYY-MM-DD H:mm:ss");

      const current = this.$moment(new Date())
        .utcOffset("+0500")
        .format("YYYY-MM-DD H:mm:ss");

      const expired = this.$moment(this.order.expiry_at)
        .utcOffset("+0500")
        .format("YYYY-MM-DD H:mm:ss");

      const time = new Date(current) - new Date(expired);
      if (time > 0) {
        this.expiredConfirm();
      }
    },

    startFetching() {
      this.isFetching = true;
    },

    finishFetching() {
      this.isFetching = false;
    },

    startSubmitting() {
      this.isSubmitting = true;
    },

    finishSubmitting() {
      this.isSubmitting = false;
    },

    async expiredConfirm() {
      if (this.isUpdateMode) {
        this.$router.go(-1);
        return;
      }

      try {
        this.startSubmitting();
        await api.orders
          .deleteOrderHold(this.order.order_hold.uuid)
          .then(() => {
            this.$router.push({
              name: "apartments",
              params: {
                object: this.$route.params.object,
              },
            });
          })
          .catch(() => this.redirect());
      } catch (error) {
        this.redirect();
        this.toastedWithErrorCode(error);
      } finally {
        this.finishSubmitting();
      }
    },

    changeStepState(idx) {
      this.stepStateIdx = idx;
    },

    async secondStepReadyToNext() {
      const clientDetailsRef = this.$refs["client-details-observer"];
      const vld = await clientDetailsRef.validateFields();
      if (vld) {
        try {
          this.startSubmitting();
          const clientForm = clientDetailsRef.sendForm();
          if (
            clientForm.attributes.passport_expiry_date &&
            new Date(clientForm.attributes.passport_expiry_date) <= new Date()
          ) {
            await this.openNotify({
              type: "error",
              message: this.$t("passportExpired"),
            });
            return false;
          }
          const { data } = await api.clientsV2.createClient(clientForm);
          if (data) {
            this.clientData = {
              ...clientForm,
              clientId: data.client_id,
              id: data.id,
            };

            const files = clientDetailsRef.getFiles();
            const uploads = await Promise.all(
              [...files].map((el) => {
                const body = new FormData();
                body.append("type", "passport_front");
                body.append("attachment", el);
                return api.uploadsV3
                  .createUpload(body)
                  .then((res) => res.data.result.id);
              })
            );
            await Promise.all(
              uploads.map(
                async (id) =>
                  await v3ServiceApi.scannedContracts.create({
                    type: "passport_front",
                    model_id: data.id,
                    upload_id: id,
                  })
              )
            );
            clientDetailsRef.openExistingScans();
            return true;
          }
          return false;
        } catch (e) {
          if (e.response.status === 422) {
            await this.openNotify({
              type: "error",
              message: Object.values(e.response.data)[0],
            });
          } else {
            await this.openNotify({
              type: "error",
              message: e.message,
            });
          }
          return false;
        } finally {
          this.finishSubmitting();
        }
      } else {
        await this.openNotify({
          type: "error",
          message: this.$t("fields_not_filled_out_or_incorrectly"),
        });
        return false;
      }
    },

    async firstStepReadyToNext() {
      const isInitialZero =
        this.$refs[
          `apartment-overview-${this.order.apartment.uuid}`
        ].getPrepay() < 1;
      if (isInitialZero) {
        if (this.permission.hasAdminRole() || this.permission.isMainManager()) {
          await this.openNotify({
            type: "warning",
            message: this.$t("checkout_warning_when_initial_set_to_zero"),
            duration: 6000,
          });
        } else {
          await this.openNotify({
            type: "error",
            message: this.$t(
              "checkout_permission_error_when_initial_set_to_zero"
            ),
            duration: 6000,
          });
          return false;
        }
      }
      const vR = await this.$refs[
        `apartment-overview-${this.order.apartment.uuid}`
      ].completeFields();
      const invalidIndex = vR ? -1 : 0;
      if (~invalidIndex) {
        await this.openNotify({
          type: "error",
          message: this.$t("fields_not_filled_out_or_incorrectly"),
        });
      }
      return true;
    },

    async submitOnUpdate() {
      try {
        this.$nextTick(() => this.changeStepState(1));
      } catch (e) {
        await this.openNotify({
          type: "error",
          message: e.response.data.message ?? e.message,
        });
      }
    },

    async moveToNextForm() {
      switch (this.stepStateIdx) {
        case 0: {
          this.changeStepState(1);
          break;
        }
        case 1: {
          if (!this.isUpdateMode) {
            const isSecondStepReady = await this.secondStepReadyToNext();
            if (isSecondStepReady) {
              this.$nextTick(() => this.changeStepState(2));
            }
          } else {
            this.openCommentModal();
          }
          break;
        }
        case 2: {
          this.openCommentModal();
        }
      }
    },
    // FINAL CALL
    async authenticateApartments() {
      try {
        this.startSubmitting();

        let priceCalculations;
        const discount =
          this.order.calculation.discount === "other"
            ? this.order.calcResult.discounts.find((el) => el.id == null)
            : this.order.calcResult.discounts.find(
                (el) => el.id === this.order.calculation.discount
              );
        if (this.order.calculation.type === "custom") {
          priceCalculations = discount.price_calculations;
        } else {
          priceCalculations = discount.installment_months.find(
            (el) => el.id === this.order.calculation.currentInstallment
          ).price_calculations;
        }
        const payload = {
          frontend_data: this.calculatorChanges,
          id: this.$route.params.id,
          status: "contract",
          client_id: this.clientData.clientId,
          currency_id: this.order.calcResult.currency.id,
          initial_payment: priceCalculations.initial_payment,
          transaction_price: priceCalculations.transaction_price,
          per_square_price: priceCalculations.per_square_price,
          discount_id:
            this.order.calculation.discount === "other"
              ? undefined
              : this.order.calculation.discount,
          discount_amount: this.order.calcResult.discount_amount,
          type: "apartment",
          object_id: this.order.apartment.block.building.object.id,

          payments: priceCalculations.payments,
          apartment: {
            id: this.order.order_apartment,
            apartment_id: this.order.apartment.id,
          },
        };

        if (this.userComment.vBind) {
          payload.order_comment = {
            comment: this.userComment.vBind,
          };
        }
        if (this.calculatorChanges.months) {
          payload.apartment.months = this.calculatorChanges.months;
        }
        if (this.order.calculation.type === "installment") {
          payload.installment_month_id =
            this.order.calculation.currentInstallment;
          payload.installment_month_price = priceCalculations.apartment_price;
        }
        if (this.dateUpdated) {
          payload.contract_date = this.order.contract_date;
        }
        if (this.numberUpdated) {
          payload.contract = this.order.contract;
          payload.apartment.contract_number = this.order.contract;
        }
        const res = await v4ServiceApi.orders.update(payload);
        const route = {
          name: "checkout-contract-review",
          params: {
            object: this.$route.params.object,
            ids: res.data.data.contract,
          },
        };
        if (!this.isUpdateMode) await this.$router.push(route);
        else
          this.$router.push({
            name: "contracts-view-new",
            params: {
              id: this.order.id,
            },
          });
      } catch (e) {
        console.log(e);
        this.closeCommentModal();
        await this.openNotify({
          type: NOTIFY.type.error,
          message: e.response.data.message,
        });
      } finally {
        this.finishSubmitting();
      }
    },
    showReviewSection() {
      this.changeStepState(2);
    },

    closeCommentModal() {
      this.userComment.showModal = false;
      this.userComment.vBind = "";
    },

    openCommentModal() {
      this.userComment.showModal = true;
    },

    deactivateOrder() {
      if (this.isUpdateMode) {
        return this.$router.go(-1);
      }
      this.openWarningModal();
    },

    hideWarningModal() {
      this.closeWarningModal();
    },

    openWarningModal() {
      this.showWarningModal = true;
    },

    closeWarningModal() {
      this.showWarningModal = false;
    },
  },
};
</script>

<template>
  <div>
    <app-header ref="app-header">
      <template #header-breadcrumb>
        <app-breadcrumb
          :page="headerItems.page"
          :page-info="headerItems.pageInfo"
          :breadcrumbs="headerItems.breadcrumbs"
          :go-back-method="deactivateOrder"
        />
      </template>
      <template v-if="isCreateMode && expiry_at" #header-actions>
        <!-- <hide-promo-button /> -->
        <div
          :class="flexCenter"
          class="checkout-timer background-violet-gradient mr-2"
        >
          <CountDown
            :deadline="expiry_at"
            :show-days="false"
            :show-hours="false"
            @timeElapsed="expiredConfirm"
          />
        </div>
      </template>
    </app-header>
    <div class="app-checkout-main">
      <b-tabs
        v-model="stepStateIdx"
        pills
        content-class="app-tabs-content"
        nav-class="app-tabs-content-header"
        :style="`opacity: ${isFetching ? 0.5 : 1}`"
      >
        <!--   TAB 1  -->
        <b-tab active>
          <template #title>
            <tab-title :step="1" :content="$t('apartment_detail')" />
          </template>

          <div>
            <ch-apartments-overview
              :ref="`apartment-overview-${order.apartment.uuid}`"
              @go-review="showReviewSection"
              :order-data="order"
              @update-calc="handleCalcUpdateWrap"
              @contract-number="handleContractNumber"
              @contract-date="handleContractDate"
              @update-payment="handleUpdatePayment"
              @add-payment="addInitialPayment"
              @delete-payment="deleteInitialPayment"
            />
          </div>
        </b-tab>
        <!--TAB 2-->
        <b-tab v-if="!isUpdateMode">
          <template #title>
            <tab-title :step="2" :content="$t('client_details')" />
          </template>

          <x-loading-wrapper :loading="isFetching">
            <ch-client-details
              ref="client-details-observer"
              @country-list="handleCountryList"
              @client-type-list="handleClientTypeList"
            />
          </x-loading-wrapper>
        </b-tab>
        <!--TAB 3-->
        <b-tab>
          <template #title>
            <tab-title
              :step="isUpdateMode ? 2 : 3"
              :content="$t('preview')"
              :show-right-icon="false"
            />
          </template>
          <ch-review
            :client="clientData"
            :country-list="countryList"
            :client-type-list="clientTypeList"
            :order="order"
            :total="totalForAll"
          />
        </b-tab>

        <template #tabs-end>
          <div class="d-flex justify-content-end align-items-center w-100">
            <div :class="flexCenter">
              <base-button
                :text="`${$t('next')}`"
                :loading="isSubmitting"
                class="violet-gradient"
                @click="moveToNextForm"
              >
                <template #right-icon>
                  <x-icon
                    name="keyboard_arrow_right"
                    size="32"
                    class="color-white"
                  />
                </template>
              </base-button>
            </div>
          </div>
        </template>
      </b-tabs>
    </div>

    <!--  COMMENT MODAL  -->
    <x-modal-center
      v-if="userComment.showModal"
      :bilingual="true"
      cancel-button-text="cancel"
      apply-button-class="w-100"
      cancel-button-class="w-100"
      apply-button-text="create_agree"
      footer-class="d-flex justify-content-between x-gap-1"
      :apply-button-loading="isSubmitting"
      @close="closeCommentModal"
      @cancel="closeCommentModal"
      @apply="authenticateApartments"
    >
      <template #header>
        <h3 class="x-font-size-36px font-craftworksans color-gray-600">
          {{ $t("create_agree_apartments") }}
        </h3>
      </template>

      <template #body>
        <div class="ch-comment-body">
          <div class="ch-comment-body-comment">
            {{ $t("comment_required_to_complete") }}
          </div>
          <x-form-input
            v-model="userComment.vBind"
            class="w-100"
            :label="true"
            :placeholder="`${$t('commentary')}`"
          />
        </div>
      </template>
    </x-modal-center>

    <!--  WARNING MODAL BEFORE LEAVE  -->
    <x-modal-center
      v-if="showWarningModal"
      :bilingual="true"
      :show-exit-button="false"
      apply-button-class="w-100"
      cancel-button-class="w-100"
      cancel-button-text="no_cancel"
      apply-button-text="yes_cancel"
      footer-class="d-flex justify-content-between x-gap-1"
      :apply-button-loading="isSubmitting"
      @close="hideWarningModal"
      @cancel="hideWarningModal"
      @apply="expiredConfirm"
    >
      <template #header>
        <h3
          class="x-font-size-36px font-craftworksans color-gray-600 d-flex align-items-center"
        >
          <x-circular-background class="bg-red-300 mr-2">
            <x-icon name="priority_high" class="red-500" />
          </x-circular-background>
          <span class="d-block">
            {{ $t("create_agree_apartments") }}
          </span>
        </h3>
      </template>

      <template #body>
        <div class="warning-before-cancel-wrapper">
          <p class="mb-0">
            {{ $t("checkout_warning_before_cancel") }}
          </p>
          <p class="mb-0">
            {{ $t("this_action_cannot_be_undone") }}
          </p>
        </div>
      </template>
    </x-modal-center>
  </div>
</template>

<style scoped lang="scss">
@import "./styles/tabs-style";
@import "src/assets/scss/utils/tab";

.app-checkout-main {
  margin-top: 2rem;
}

.checkout-timer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 30px;
  color: var(--white);
  border-radius: 28px;
  height: 56px;
  min-width: 102px;

  &-inner {
    padding: 13px 16px;
  }
}

.ch-comment-body {
  font-family: Inter, sans-serif;
  margin-top: 3rem;
  margin-bottom: 3rem;

  &-comment {
    margin-bottom: 1rem;
  }
}

.warning-before-cancel-wrapper {
  margin: 3.5rem 0;
  color: var(--gray-600);
  font-family: Inter, sans-serif;
}

::v-deep {
  .custom-tab {
    margin-top: 0;
    position: relative;

    .ch-bottom__line {
      display: flex;
      height: 8px;
      width: calc(100% + 6rem);
      position: absolute;
      top: -8px;
      left: -3rem;
      background-color: var(--gray-100);
    }

    .sticky-top {
      position: -webkit-sticky !important; /* Safari */
      position: sticky !important;
      padding-top: 2rem;
      margin-top: -2rem;
      top: 0;
      z-index: 4;
      width: calc(100% + 6rem);
      right: auto !important;
      left: 0 !important;
      height: 100%;
      margin-left: -3rem;
      box-shadow: none;
      background-color: var(--white);
      transition: all 0.3s linear;

      .nav-tabs {
        background-color: var(--white);
        width: 100%;
        padding-left: 3rem;
        padding-right: 3rem;

        &:after {
          content: "";
          display: flex;
          height: 8px;
          width: calc(100% + 3rem);
          position: absolute;
          bottom: 0;
          left: -3rem;
          background-color: var(--gray-100);
        }
      }
    }

    .fixed-top {
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.16);
      transition: all 0.3s linear;
    }
  }
}
.ch-v-status-wrapper {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}
</style>
